$primary: #2E8429 !default;
$secondary: #66C4EE !default;
$enable-rounded: false;
$border-width: 2px;

$body-font-family: 'Roboto', sans-serif;
$font-weight-base: 400;

$headings-font-family: 'Dosis', 'Roboto', sans-serif;
$headings-font-weight: 700;

$btn-font-family: 'Dosis', 'Roboto', sans-serif;
$btn-font-weight: 700;

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import '../../../../src/scss/main.scss';

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.btn {
  text-transform: uppercase;
}
